@font-face {
  font-family: "Riffic";
  src: url("./assets/fonts/riffic/RifficFree-Bold.ttf");
}
@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/roboto/Roboto-Regular.ttf");
}
:root{
  --blue:#00487C;
  --light-gray:#e9e9e9;
  --median-gray:#d1d1d1;
  --dark-gray:#333;
  --background-gray:#f5f5f5;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto';
}
html,
body,
#root{
  height: 100%;
  background-color: var(--background-gray);
  overflow-x: hidden;
}