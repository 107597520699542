.home {
  width: 100%;
  height: 80vh;
  margin-top: 4rem;
  display: flex;
  position: relative;
  padding: 0 2rem;
}

.left-description {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.home-image {
  object-fit: cover;
  width: 100%;
}
.sub-title {
  color: var(--blue);
  font-family: "Riffic";
  font-size: 1.8rem;
  margin-bottom: 0.8rem;
}
.why-quote{
  font-style: italic;
  text-align: center;
  color: rgb(104, 104, 104)
}
.container-description {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
  row-gap: 1.5rem;
  font-size: 1.6rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  text-align: left;
}

.right-description {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 2rem;
  position: relative;
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 900px) {
  .home {
    flex-direction: column-reverse;
    height: auto;

  }
  .left-description {
    width: 100%;
  }
  .right-description {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .home {
    padding: 0 0.1rem;
  }
  .left-description {
    margin-top: 0;
  }
  .container-description {
    font-size: 1rem;
  }
  .sub-title {
    font-size: 1.5rem;
  }
}
