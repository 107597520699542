.viewer {
  width: 100vw;
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.viewer-warning {
  color: var(--blue);
  font-family: "Riffic";
  font-size: 1.8rem;
  margin-bottom: 0.8rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
}
.frame {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}
