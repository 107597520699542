.gallery{
    width: 100vw;
    min-height: 100vh;
}
.gallery-title{
    color: var(--blue);
    font-family: "Riffic";
    font-size: 1.8rem;
    text-align: center;
    padding-top: 2rem;
    margin-bottom: 2rem;
}
.gallery-description{
    font-size: 1.6rem;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    text-align: center;
    margin-bottom: 2rem;
}
.gallery-container{
    width: 100vw;
    min-height: 100vh;
    background-color: var(--background-grey);
    display: flex;
    padding: 0rem 1rem;
    flex-wrap: wrap;
    gap: .5rem;
    row-gap: .5rem;
    justify-content: center;
}
.select-image{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.733);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 12;
    overflow: hidden;
}
.photo-description-selected{
    position: absolute;
    bottom: 10%;
    color: var(--background-gray);
    padding: .8rem;
    transition: .5s;
    z-index: 12;
    font-weight: bold;
    text-align: center;
    background-color: var(--blue);
    border-radius: .2rem;
}

.selected-photo{
    max-width: 90%;
    max-height: 90%;
    height: auto;
    width: auto;
    object-fit: cover;
    cursor: pointer;
}
.gallery-photo-container{
    max-width: 25rem;
    width: 100%;
    height: auto;
    min-height: 10rem;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    
}
.photo{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .5s;
    background-color: var(--median-gray);

}
.photo-description{
    position: absolute;
    color: var(--background-gray);
    padding: .8rem;
    bottom: 0;
    opacity: 0;
    transition: .5s;
    z-index: 4;
    font-weight: bold;
    transform: translate(0,50%);
}

.gallery-photo-container:hover .photo-description {
    opacity: 1;
    transform: translate(0,0);
}

.photo:hover{
    transform: scale(1.1);
    filter: brightness(0.6);
}

@media screen and (max-width: 600px) {
    .gallery-title{
        font-size: 1.5rem;
    }
    .gallery-description{
        font-size: 1rem;
    }

}