.nav-bar{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3rem;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-mideia-links{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    position: absolute;
    right: 13rem;
}
.ocean-vision-logo{
    margin: .5rem;
    width: 10rem;
    fill: white;
    position: absolute;
    left: 2rem;
}
@media screen and (max-width: 900px){
    .social-mideia-links{
        display: none;
    }
    .ocean-vision-logo{
        left:1rem
    }
}
@media screen and (max-width: 600px){
    .ocean-vision-logo{
        left:0rem;
        width: 4rem;
    }
}